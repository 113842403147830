/**
 * Global configurations that applies to both apps/api and apps/web.
 */

export const config = {
  storage: {
    publicImagesBucket: "public_images"
  },
  avatar: {
    width: 500,
    height: 500,
    quality: 100
  },
  banner: {
    width: 600,
    height: 200,
    quality: 100
  },
  tier: {
    free: {
      pledgeAmount: 0,
      maxContextTokens: 2048,
      maxResponseTokens: 1024
    },
    "tier-1": {
      pledgeAmount: 1500,
      maxContextTokens: 4096,
      maxResponseTokens: 2048
    },
    "tier-2": {
      pledgeAmount: 2500,
      maxContextTokens: 4096,
      maxResponseTokens: 2048
    }
  },
  usageResetIntervalHours: 3
};
