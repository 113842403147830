import { z } from "zod";

export const periodSchema = z.enum(["day", "week", "month", "year", "all"]).optional();
export type Period = z.infer<typeof periodSchema>;
export const orderSchema = z.enum(["asc", "desc"]).optional();
export type Order = z.infer<typeof orderSchema>;
const rankingSchema = z.enum(["top", "hot", "new"]).optional();
export type Ranking = z.infer<typeof rankingSchema>;

export const PAGE_SIZE = 21;

export const searchSchema = z.object({
  ranking: rankingSchema,
  searchText: z.string().optional(),
  page: z.number().min(1).optional().catch(1),
  order: orderSchema,
  period: periodSchema,
  focusExplore: z.boolean().optional()
});
