import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

/**
 * Cleans a file path by removing any consecutive forward slashes.
 *
 * @param path - The file path to clean.
 * @returns The cleaned file path.
 */
export function cleanPath(path: string) {
  return path.replace(/\/{2,}/g, "/");
}

/**
 * Joins an array of string paths into a single cleaned path.
 *
 * @param paths - An array of string paths to join.
 * @returns The joined and cleaned path.
 * @example
 * joinPaths("foo", "bar", "baz"); // "foo/bar/baz"
 * joinPaths("foo", "/bar", "baz"); // "foo/bar/baz"
 */
export function joinPaths(...paths: string[]) {
  return cleanPath(
    paths
      .filter((val) => {
        return val !== void 0;
      })
      .join("/")
  );
}

/**
 * Creates an array of elements split into groups the length of size.
 * If array can't be split evenly, the final chunk will be the remaining elements.
 *
 * @param array - The array to process.
 * @param size - The length of each chunk.
 * @returns The new array of chunks.
 */
export function chunk<T>(array: T[], size: number): T[][] {
  if (size <= 0) {
    throw new Error("Size must be a positive integer.");
  }

  const result: T[][] = [];
  let index = 0;

  while (index < array.length) {
    result.push(array.slice(index, index + size));
    index += size;
  }

  return result;
}

/**
 * Creates a deeply readonly version of a type.
 * Recursively makes all properties and nested properties of an object or array readonly.
 *
 * @template T - The type to make deeply readonly
 *
 * @example
 * type Person = {
 *   name: string;
 *   age: number;
 *   address: {
 *     street: string;
 *     city: string;
 *   };
 *   hobbies: string[];
 * };
 *
 * type ReadonlyPerson = DeepReadonly<Person>;
 * // Result:
 * // {
 * //   readonly name: string;
 * //   readonly age: number;
 * //   readonly address: {
 * //     readonly street: string;
 * //     readonly city: string;
 * //   };
 * //   readonly hobbies: readonly string[];
 * // }
 */
export type DeepReadonly<T> = T extends (infer R)[]
  ? ReadonlyArray<DeepReadonly<R>>
  : T extends (...args: any[]) => any
    ? T
    : T extends object
      ? { readonly [P in keyof T]: DeepReadonly<T[P]> }
      : T;

export function debounce(fn: (...args: any[]) => void, ms: number) {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  return function wrapped(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      fn(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, ms);
  };
}
