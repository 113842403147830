import { getFileExtension } from "@moe/oss/utils/utils";
import { config } from "@moe/priv/config";
import { sb } from "@web/lib/supabase";
import { v4 } from "uuid";

export async function uploadFile(file: File): Promise<string> {
  const ext = getFileExtension(file.name);
  const path = `${v4()}.${ext}`;
  const { data, error } = await sb.storage.from(config.storage.publicImagesBucket).upload(path, file);
  if (error) throw error;
  return data.path;
}

export async function removeFile(fileName: string): Promise<void> {
  const { error } = await sb.storage.from(config.storage.publicImagesBucket).remove([fileName]);
  if (error) throw error;
}

// Function to format large numbers
export function formatCount(count: number) {
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}m`;
  } else if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}k`;
  } else {
    return count.toString();
  }
}
