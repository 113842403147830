import { Dialog, DialogContent } from "@moe/oss/ui/dialog";
import { ModalConfig } from "@moe/priv/types/types";
interface Props {
  config?: ModalConfig;
  setConfig: (config?: ModalConfig) => void;
}

export function ModalProvider({ config, setConfig }: Props) {
  return (
    <>
      {config && (
        <Dialog
          open={!!config}
          onOpenChange={() => {
            setConfig(undefined);
          }}
        >
          <DialogContent
            className="w-fit max-w-none border-none p-0 outline-none"
            onInteractOutside={(e) => {
              if (config.onInteractOutside) {
                config.onInteractOutside(e);
              }
            }}
          >
            {config.content}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
