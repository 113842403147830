export const characterConfig = {
  nameMinChars: 1,
  nameMaxChars: 128,

  descriptionMinChars: 0,
  descriptionMaxChars: 4096,

  greetingMinChars: 1,
  greetingMaxChars: 2048,

  definitionsMinChars: 0,
  definitionsMaxChars: 8192,

  msgExamplesMinChars: 1,
  msgExamplesMaxChars: 2048,

  avatarWidth: 400,
  avatarHeight: 600,
  avatarMaxFileSizeBytes: 3e6,

  bannerWidth: 1500,
  bannerHeight: 500,
  bannerMaxFileSizeBytes: 3e6
};

export interface Character {
  id: number;
  name: string;
  greeting: string;
  avatar: string;
  banner?: string;
  isNSFW: boolean;
  isPrivate: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CharacterInsert {
  name: string;
  description: string;
  greeting: string;
  definitions?: string;
  message_examples?: string;
  avatar: File;
  banner?: File;
  isNSFW?: boolean;
  isPrivate?: boolean;
}

export interface CharacterUpdate {
  id: number;
  name: string;
  description: string;
  greeting: string;
  definitions: string;
  message_examples: string;
  avatar?: File;
  banner?: File;
  isNSFW?: boolean;
  isPrivate?: boolean;
}
