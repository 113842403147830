import { DialogConfig, ModalConfig } from "@moe/priv/types/types";
import { Session } from "@supabase/supabase-js";
import { CardAccessor } from "@web/accessor/card";
import { ChatAccessor } from "@web/accessor/chat";
import { MessageAccessor } from "@web/accessor/message";
import { PersonaAccessor } from "@web/accessor/persona";
import { ProfileAccessor } from "@web/accessor/profile";
import { createContext, useContext } from "react";

interface Settings {
  nsfwOK: boolean;
}

export interface Access {
  chat: ChatAccessor;
  card: CardAccessor;
  message: MessageAccessor;
  persona: PersonaAccessor;
  profile: ProfileAccessor;
}

export interface AppContextProps {
  createDialog: (config: DialogConfig) => void;
  createModal: (config: ModalConfig) => void;
  closeModal: () => void;
  session: Session | undefined;
  settings: Settings | undefined;
  access: Access;
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export function useAppContext() {
  const context = useContext(AppContext);
  if (context === undefined) throw new Error("useApp must be used within an App Provider");
  return context;
}
