import { Toaster } from "@moe/oss/ui/sonner";
import { TooltipProvider } from "@moe/oss/ui/tooltip";
import { toolTipDelay } from "@moe/priv/components/tip";
import { useShiftKey } from "@moe/priv/hooks/use-shiftkey";
import { DialogConfig, ModalConfig } from "@moe/priv/types/types";
import { Session } from "@supabase/supabase-js";
import { QueryClient, QueryClientConfig, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { createRootRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { CardAccessor } from "@web/accessor/card";
import { CharacterAccessor } from "@web/accessor/character";
import { ChatAccessor } from "@web/accessor/chat";
import { MessageAccessor } from "@web/accessor/message";
import { PersonaAccessor } from "@web/accessor/persona";
import { ProfileAccessor } from "@web/accessor/profile";
import { sb } from "@web/lib/supabase";
import { client, reactClient, trpc } from "@web/lib/trpc";
import { AppContext, AppContextProps } from "@web/route-services/root/AppContext";
import { DevTools } from "@web/route-services/root/DevTools";
import { DialogProvider } from "@web/route-services/root/DialogProvider";
import { ModalProvider } from "@web/route-services/root/ModalProvider";
import { useEffect, useMemo, useState } from "react";

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: (attemptIndex: number) => Math.min(2500 * 2 ** attemptIndex, 30000),
      retry: (failureCount) => {
        return failureCount < 3;
      }
    }
  }
};
export const queryClient = new QueryClient(queryClientConfig);

export const Route = createRootRoute({ component: RootComponent });
function RootComponent() {
  const [alertConfig, setAlertConfig] = useState<DialogConfig | undefined>();
  const [modalConfig, setModalConfig] = useState<ModalConfig>();
  const isShiftKeyPressed = useShiftKey();
  const [session, setSession] = useState<Session>();
  const [trpcReact] = useState(reactClient);
  const navigate = useNavigate();

  // When resetting password, Supabase sends the user an email with a link
  // When they click on the link they are directed to the app, and a PASSWORD_RECOVERY event is triggered
  // We shuld redirect them to the reset password page
  useEffect(() => {
    sb.auth.onAuthStateChange(async (event, _session) => {
      if (event === "PASSWORD_RECOVERY") {
        navigate({ to: "/settings/reset-password" });
      }
    });
  }, [navigate]);

  const { data: settings } = useQuery(
    {
      queryKey: ["settings", session?.user.id],
      queryFn: async () => {
        const id = session?.user.id;
        if (!id) return;
        const { data, error } = await sb.from("profile_settings").select("nsfw_ok").eq("id", id).single();
        if (error) return;
        return {
          nsfwOK: data.nsfw_ok
        };
      },
      enabled: !!session?.user.id
    },
    queryClient
  );

  // Update session information
  useEffect(() => {
    sb.auth.getSession().then(({ data: { session } }) => {
      setSession(session ?? undefined);
    });
    const {
      data: { subscription }
    } = sb.auth.onAuthStateChange((_event, session) => {
      setSession(session ?? undefined);
    });
    return () => subscription.unsubscribe();
  }, []);

  const access = useMemo(() => {
    const userID = session?.user.id;
    const chat = new ChatAccessor({ userID, trpc: client });
    const character = new CharacterAccessor();
    const card = new CardAccessor({ character, userID, trpc: client });
    const message = new MessageAccessor({ userID });
    const persona = new PersonaAccessor({ userID });
    const profile = new ProfileAccessor({ userID });
    return {
      chat,
      character,
      card,
      message,
      persona,
      profile
    };
  }, [session?.user.id]);

  const appContextValue: AppContextProps = {
    createDialog: (config) => {
      if (isShiftKeyPressed) {
        config.onAction();
        return;
      }
      setAlertConfig(config);
    },
    createModal: (config) => {
      setModalConfig(config);
    },
    closeModal: () => setModalConfig(undefined),
    session,
    settings,
    access
  };

  return (
    <trpc.Provider client={trpcReact} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={appContextValue}>
          <TooltipProvider delayDuration={toolTipDelay}>
            <DialogProvider config={alertConfig} setConfig={setAlertConfig} />
            <ModalProvider config={modalConfig} setConfig={setModalConfig} />
            <DevTools />
            <Outlet />
            <Toaster position="top-right" />
          </TooltipProvider>
        </AppContext.Provider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
