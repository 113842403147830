import {
  BaseMessage,
  CandidateMessage,
  Message,
  MessageInsert,
  MessageUpdate,
  UserMessage
} from "@moe/priv/model/message";
import { TablesInsert } from "@moe/priv/types/sb-types";
import { QueryData } from "@supabase/supabase-js";
import { ID } from "@web/accessor/base";
import { sb } from "@web/lib/supabase";
import { DeepPartial } from "react-hook-form";

/** The (R)esource type */
type R = Message;

/** The type used for (I)nserts, defaultable fields are optional. */
type I = MessageInsert;

/** The type used for (U)pdates, all fields are optional. */
type U = MessageUpdate;

interface GetPageOptions {
  page: number;
  pageSize: number;
  order: "asc" | "desc";
  filter: {
    searchText: string;
  };
}

namespace Q {
  export namespace All {
    export const selector = `id, text, character_id, prime_candidate_id, created_at, updated_at, candidate_messages!candidate_messages_message_id_fkey(id, text, created_at, updated_at)`;
    const builder = sb.from("messages").select(selector);
    export type Data = QueryData<typeof builder>[number];
  }
}

export class MessageAccessor {
  private readonly userID?: string;

  constructor({ userID }: { userID?: string }) {
    this.userID = userID;
  }

  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    const q = sb.from("messages").select(Q.All.selector);
    if (Array.isArray(id)) {
      const { data, error } = await q.in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }
    const { data, error } = await q.eq("id", id).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  async create(value: I): Promise<UserMessage>;
  async create(value: I[]): Promise<UserMessage[]>;
  async create(value: I | I[]): Promise<UserMessage | UserMessage[]> {
    if (Array.isArray(value)) return Promise.all(value.map((v) => this.create(v)));

    const messageValue: TablesInsert<"messages"> = {
      text: value.text,
      character_id: value.characterID,
      chat_id: value.chatID
    };

    const { data, error } = await sb.from("messages").insert(messageValue).select(Q.All.selector).single();

    if (error) throw error;
    return this.processAllQuery(data) as UserMessage;
  }

  async update(value: U): Promise<R>;
  async update(value: U[]): Promise<R[]>;
  async update(value: U | U[]): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map(async (v) => this.update(v)));

    switch (value.kind) {
      case "user":
      case "base": {
        const { data, error } = await sb
          .from("messages")
          .update({ text: value.text })
          .eq("id", value.id)
          .select(Q.All.selector)
          .single();
        if (error) throw error;
        return this.processAllQuery(data);
      }
      case "candidate": {
        const { error } = await sb.from("candidate_messages").update({ text: value.text }).eq("id", value.id).single();
        if (error) throw error;
        const { data, error: selectError } = await sb
          .from("messages")
          .select(Q.All.selector)
          .eq("id", value.id)
          .single();
        if (selectError) throw selectError;
        return this.processAllQuery(data);
      }
      default:
        throw new Error(`Invalid kind ${value.kind}`);
    }
  }

  async remove(id: ID | ID[]): Promise<void> {
    if (!Array.isArray(id)) id = [id];
    const { error } = await sb.from("messages").delete().in("id", id);
    if (error) throw error;
  }

  async getPageByChatID(chatID: ID, options?: DeepPartial<GetPageOptions>): Promise<R[]> {
    if (!this.userID) throw new Error("User is unauthenticated while getting messages.");
    const opt = this.normalizeGetPageOptions(options);
    const rangeStart = opt.page * opt.pageSize;
    // -1 to make range non-inclusive
    const rangeEnd = rangeStart + opt.pageSize - 1;

    const q = sb
      .from("messages")
      .select(Q.All.selector)
      .eq("chat_id", chatID)
      .eq("created_by", this.userID)
      .range(rangeStart, rangeEnd)
      .order("created_at", { ascending: opt.order === "asc" });

    const { data, error } = await q;
    if (error) throw error;
    return this.processAllQuery(data).reverse();
  }

  private normalizeGetPageOptions(options?: DeepPartial<GetPageOptions>): GetPageOptions {
    return {
      page: options?.page ?? 0,
      pageSize: options?.pageSize ?? 30,
      order: options?.order ?? "desc",
      filter: {
        searchText: options?.filter?.searchText ?? ""
      }
    };
  }

  async setChosen(messageID: number, candidateID?: number): Promise<R> {
    const { data, error } = await sb
      .from("messages")
      .update({ prime_candidate_id: candidateID ?? null })
      .eq("id", messageID)
      .select(Q.All.selector)
      .single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  private processAllQuery(value: Q.All.Data[]): R[];
  private processAllQuery(value: Q.All.Data): R;
  private processAllQuery(value: Q.All.Data | Q.All.Data[]): R | R[] {
    if (Array.isArray(value)) return value.map((v) => this.processAllQuery(v));
    if (value.character_id === null) {
      const userMessage: UserMessage = {
        id: value.id,
        kind: "user",
        text: value.text,
        createdAt: value.created_at,
        updatedAt: value.updated_at ?? undefined
      };
      return userMessage;
    }
    const base: BaseMessage = {
      id: value.id,
      kind: "base",
      text: value.text,
      characterID: value.character_id,
      isChosen: value.prime_candidate_id === null,
      createdAt: value.created_at,
      updatedAt: value.updated_at ?? undefined
    };
    const candidates: CandidateMessage[] = [];
    for (const c of value.candidate_messages) {
      const candidateMessage: CandidateMessage = {
        id: c.id,
        kind: "candidate",
        text: c.text,
        characterID: value.character_id,
        isChosen: value.prime_candidate_id === c.id,
        createdAt: c.created_at,
        updatedAt: c.updated_at ?? undefined
      };
      candidates.push(candidateMessage);
    }
    return [base, ...candidates];
  }
}
