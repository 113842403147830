import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { motion } from "framer-motion"; // Import Framer Motion
import * as React from "react";
import { cn } from "../utils/utils";

const buttonVariants = cva(
  "flex items-center transition justify-center duration-200 ease-out whitespace-nowrap rounded-md text-sm font-medium transition focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-action-primary text-tx-primary hover:brightness-90 text-base px-4 py-2",
        secondary: "text-tx-secondary hover:text-tx-primary bg-transparent px-4 py-2",
        shell:
          "p-3 disabled:hover:bg-transparent active:hover:bg-accent aspect-square cursor-pointer shadow-md flex justify-center items-center size-fit rounded-full border border-line disabled:shadow-none"
      },
      size: {
        default: "h-10"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface ButtonProps extends VariantProps<typeof buttonVariants> {
  className?: string;
  asChild?: boolean;
  [key: string]: any;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    if (variant === "shell" && !props.disabled) {
      const shellButtonVariants = {
        initial: { scale: 1 },
        pressed: { scale: 0.9 }
      };
      return (
        <motion.button
          className={cn(buttonVariants({ variant: "shell", size, className }))}
          whileTap="pressed"
          variants={shellButtonVariants}
          transition={{
            type: "spring",
            duration: 0.05
          }}
          {...props}
        />
      );
    }

    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
