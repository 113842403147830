/**
 * @file Internal accessors for the characters table.
 * This module should not be exposed to the View layer.
 * Instead, it should only be used by other accessors.
 */

import { config } from "@moe/priv/config";
import { Character, CharacterInsert, CharacterUpdate } from "@moe/priv/model/character";
import { TablesInsert, TablesUpdate } from "@moe/priv/types/sb-types";
import { QueryData } from "@supabase/supabase-js";
import { ID } from "@web/accessor/base";
import { sb } from "@web/lib/supabase";
import { removeFile, uploadFile } from "@web/lib/utils";

/** The (R)esource type */
type R = Character;

/** The type used for (I)nserts, defaultable fields are optional. */
type I = CharacterInsert;

/** The type used for (U)pdates, all fields are optional. */
type U = CharacterUpdate;

namespace Q {
  export namespace All {
    export const selector =
      "id, name, greeting,  avatar_file_name, banner_file_name, is_nsfw, is_private, created_by, created_at, updated_at" as const;
    const builder = sb.from("characters").select(selector);
    export type Data = QueryData<typeof builder>[number];
  }
}

export class CharacterAccessor {
  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    const q = sb.from("characters").select(Q.All.selector);
    if (Array.isArray(id)) {
      const { data, error } = await q.in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }
    const { data, error } = await q.eq("id", id).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  async create(value: I): Promise<R>;
  async create(value: I[]): Promise<R[]>;
  async create(value: I | I[]): Promise<R | R[]>;
  async create(value: I | I[]): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map((v) => this.create(v)));
    const avatar = value.avatar;
    const banner = value.banner;

    const avatarFileName = await uploadFile(avatar);
    let bannerFileName: string | undefined;
    if (banner) bannerFileName = await uploadFile(banner);

    const insertData = {
      name: value.name,
      description: value.description,
      greeting: value.greeting,
      definitions: value.definitions,
      avatar_file_name: avatarFileName,
      banner_file_name: bannerFileName,
      is_nsfw: value.isNSFW,
      is_private: value.isPrivate
    } satisfies TablesInsert<"characters">;

    const { data, error } = await sb.from("characters").insert(insertData).select(Q.All.selector).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  async update(value: U): Promise<R>;
  async update(value: U[]): Promise<R[]>;
  async update(value: U | U[]): Promise<R | R[]>;
  async update(value: U | U[]): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map(async (v) => this.update(v)));

    const { data: old, error: oldError } = await sb
      .from("characters")
      .select("avatar_file_name, banner_file_name")
      .eq("id", value.id)
      .single();
    if (oldError) throw oldError;

    const avatar = value.avatar;
    const banner = value.banner;
    let avatarFileName: string | undefined;
    let bannerFileName: string | undefined;
    if (avatar) avatarFileName = await uploadFile(avatar);
    if (banner) bannerFileName = await uploadFile(banner);
    const updateValue: TablesUpdate<"characters"> = {
      name: value.name,
      description: value.description,
      greeting: value.greeting,
      definitions: value.definitions,
      avatar_file_name: avatarFileName,
      banner_file_name: bannerFileName,
      is_nsfw: value.isNSFW
    };

    const { data, error } = await sb
      .from("characters")
      .update(updateValue)
      .eq("id", value.id)
      .select(Q.All.selector)
      .single();
    if (error) throw error;

    // Remove old files if they were updated
    if (old.avatar_file_name && avatarFileName) await removeFile(old.avatar_file_name);
    if (old.banner_file_name && bannerFileName) await removeFile(old.banner_file_name);
    return this.processAllQuery(data);
  }

  async remove(id: ID | ID[]): Promise<void> {
    if (!Array.isArray(id)) id = [id];
    const { data: oldData, error: oldError } = await sb
      .from("characters")
      .select("avatar_file_name, banner_file_name")
      .in("id", id);
    if (oldError) throw oldError;

    const { error } = await sb.from("characters").delete().in("id", id);
    if (error) throw error;

    // Remove associated files
    for (const item of oldData) {
      if (item.avatar_file_name) await removeFile(item.avatar_file_name);
      if (item.banner_file_name) await removeFile(item.banner_file_name);
    }
  }

  processAllQuery(value: Q.All.Data[]): R[];
  processAllQuery(value: Q.All.Data): R;
  processAllQuery(value: Q.All.Data | Q.All.Data[]): R | R[];
  processAllQuery(value: Q.All.Data | Q.All.Data[]): R | R[] {
    if (Array.isArray(value)) return value.map((r) => this.processAllQuery(r));

    let banner: string | undefined;
    const avatar = sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(value.avatar_file_name, {
      transform: {
        width: config.avatar.width,
        height: config.avatar.height,
        quality: config.avatar.quality
      }
    }).data.publicUrl;

    if (value.banner_file_name) {
      banner = sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(value.banner_file_name, {
        transform: {
          width: config.banner.width,
          height: config.banner.height,
          quality: config.banner.quality
        }
      }).data.publicUrl;
    }
    return {
      id: value.id,
      name: value.name,
      greeting: value.greeting,
      avatar,
      banner,
      isNSFW: value.is_nsfw,
      isPrivate: value.is_private,
      createdBy: value.created_by,
      createdAt: value.created_at,
      updatedAt: value.updated_at ?? undefined
    };
  }
}
