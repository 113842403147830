// @ts-ignore
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AppRouter } from "@api/trpc/router";
import { createTRPCClient, createTRPCReact, httpBatchLink } from "@trpc/react-query";
import { config } from "@web/lib/config";
import superjson from "superjson";
import { sb } from "./supabase";

export const trpc = createTRPCReact<AppRouter>();

export const client = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: config.apiURL + "/trpc",
      transformer: superjson,
      async headers() {
        const token = (await sb.auth.getSession()).data.session?.access_token;
        return token ? { Authorization: `Bearer ${token}` } : {};
      }
    })
  ]
});
export type Client = typeof client;

export const reactClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: config.apiURL + "/trpc",
      transformer: superjson,
      async headers() {
        const sessionToken = (await sb.auth.getSession()).data.session?.access_token;
        return sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {};
      }
    })
  ]
});
export type ReactClient = typeof reactClient;
