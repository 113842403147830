import { config } from "@web/lib/config";
import React, { useEffect, useState } from "react";

export function DevTools() {
  // Toggle dev tools in prod
  const [isDebugMode, setIsDebugMode] = useState(() => {
    const saved = localStorage.getItem("isDebugMode");
    const parsed = saved ? (JSON.parse(saved) as boolean) : false;
    // Dev tools is enabled when either:
    // - We are in a development environment
    // - Or we're in prod and we have set the isDebugMode flag
    return parsed || config.env.dev;
  });

  // Attach .debug() to the global window object
  // this allows us to toggle dev tools in prod with window.debug()
  useEffect(() => {
    window.debug = () => {
      setIsDebugMode(!isDebugMode);
      localStorage.setItem("isDebugMode", JSON.stringify(!isDebugMode));
    };
    return () => {
      delete window.debug;
    };
  }, [isDebugMode]);

  const ReactQueryDevTools = isDebugMode
    ? React.lazy(() =>
        import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
          default: d.ReactQueryDevtools
        }))
      )
    : () => null;

  const Cmd = isDebugMode
    ? React.lazy(() =>
        import("./cmd").then((mod) => ({
          default: mod.Cmd
        }))
      )
    : () => null;

  const TanstackRouterDevTools = isDebugMode
    ? React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools
        }))
      )
    : () => null;

  return (
    <>
      <React.Suspense fallback={null}>
        <ReactQueryDevTools />
      </React.Suspense>
      <React.Suspense fallback={null}>{<Cmd />}</React.Suspense>
      <React.Suspense fallback={null}>
        <TanstackRouterDevTools />
      </React.Suspense>
    </>
  );
}
