import { Character, characterConfig, CharacterInsert, CharacterUpdate } from "@moe/priv/model/character";

export const cardConfig = {
  titleMinChars: 1,
  titleMaxChars: 128,

  tagLineMinChars: 0,
  taglineMaxChars: 256,
  character: characterConfig
};

export interface Card {
  id: number;
  title: string;
  tagline: string;
  characters: Character[];
  // The UUID of a user on agf
  createdBy: string;
  // A username string of an external user, this field is available on cards
  // that were imported from external sources.
  externalCreatedBy?: string;
  createdAt: string;
  updatedAt?: string;
  chatCount: number;
  messageCount: number;
  likeCount: number;
  isLiked: boolean;
  isNSFW: boolean;
  isPrivate: boolean;
}

export interface CardInsert {
  title: string;
  tagline: string;
  characters: CharacterInsert[];
  isNSFW: boolean;
  isPrivate: boolean;
}

export interface CardUpdate {
  id: number;
  title: string;
  tagline: string;
  characters: CharacterUpdate[];
  isNSFW: boolean;
  isPrivate: boolean;
}
