/**
 * @file Accessor for the Card resource.
 * A card contains one or more characters.
 */

import { Card, CardInsert, CardUpdate } from "@moe/priv/model/card";
import { TablesInsert } from "@moe/priv/types/sb-types";
import { CardService, DeepPartial, FormCard } from "@moe/priv/types/types";
import { QueryData } from "@supabase/supabase-js";
import { ID } from "@web/accessor/base";
import { CharacterAccessor } from "@web/accessor/character";
import { sb } from "@web/lib/supabase";
import { Client, client } from "@web/lib/trpc";
import { uploadFile } from "@web/lib/utils";

type Period = CardService.Period;
type TimeRange = CardService.TimeRange;
type GetPageOptions = CardService.GetPageOptions;
type GetLastPageOptions = CardService.GetLastPageOptions;

/** The (R)esource type */
type R = Card;
/** The type used for (I)nserts, defaultable fields are optional. */
type I = CardInsert;
/** The type used for (U)pdates, all fields are optional.  */
type U = CardUpdate;

namespace Q {
  export namespace All {
    export const selector = `id, title, tagline, total_like_count, chat_count, total_message_count, hotness, is_private, is_nsfw, created_by, external_created_by, created_at, updated_at, 
  characters (id, name, greeting, avatar_file_name, banner_file_name, is_nsfw, is_private, created_by, created_at, updated_at),
  profiles!inner (username), card_likes(profile_id)`;
    const builder = sb.from("cards").select(selector);
    export type Builder = typeof builder;
    export type Data = QueryData<typeof builder>[number];
  }
  // Guest query, no card_likes field
  export namespace AllGuest {
    export const selector = `id, title, tagline, total_like_count, chat_count, total_message_count, hotness, is_private, is_nsfw, created_by, external_created_by, created_at, updated_at,
  characters (id, name, greeting, avatar_file_name, banner_file_name, is_nsfw, is_private, created_by, created_at, updated_at),
  profiles!inner (username)`;
    const builder = sb.from("cards").select(selector);
    export type Builder = typeof builder;
    export type Data = QueryData<typeof builder>[number];
  }
  export namespace Count {
    export const selector = [`id`, { count: "planned", head: true }] as const;
    const builder = sb.from("cards").select(...selector);
    export type Builder = typeof builder;
  }
}

interface Constructor {
  character: CharacterAccessor;
  userID?: string;
  trpc: Client;
}

export class CardAccessor {
  private character: CharacterAccessor;
  private userID?: string;
  private trpc: Client;

  constructor({ character, userID, trpc }: Constructor) {
    this.character = character;
    this.userID = userID;
    this.trpc = trpc;
  }

  /**
   * Retrieves an array of cards that were created by the authenticated user.
   *
   * @returns A promise that resolves to an array of cards created by the authenticated user.
   * @throws Error if the user is not authenticated.
   */
  async getSelf(): Promise<R[]> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to get self owned cards");
    const q = sb
      .from("cards")
      .select(Q.All.selector)
      .eq("created_by", this.userID)
      .eq("card_likes.profile_id", this.userID);
    const { data, error } = await q;
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Retrieves cards by username.
   *
   * @param username - The username to filter the cards by.
   * @returns A promise that resolves to an array of cards.
   */
  async getByUsername(username: string): Promise<R[]> {
    const q = sb.from("cards");
    let res;
    if (this.userID) {
      res = await q
        .select(Q.All.selector)
        .eq("card_likes.profile_id", this.userID)
        .eq("profiles.username", username)
        .order("created_at", { ascending: false });
    } else {
      res = await q
        .select(Q.AllGuest.selector)
        .eq("profiles.username", username)
        .order("created_at", { ascending: false });
    }
    const { data, error } = res;
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Retrieves a card or a list of cards by their ID.
   *
   * @param id - The ID or an array of IDs of the cards to retrieve.
   * @returns A promise that resolves to the card or an array of cards with the specified ID(s).
   */
  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    // Authenticated array query
    if (Array.isArray(id) && this.userID) {
      const { data, error } = await sb
        .from("cards")
        .select(Q.All.selector)
        .in("id", id)
        .eq("card_likes.profile_id", this.userID);
      if (error) throw error;

      return this.processAllQuery(data);
    }

    // Guest array query
    if (Array.isArray(id) && !this.userID) {
      const { data, error } = await sb.from("cards").select(Q.AllGuest.selector).in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }

    // Authenticated single query
    if (this.userID) {
      const { data, error } = await sb
        .from("cards")
        .select(Q.All.selector)
        .eq("id", id)
        .eq("card_likes.profile_id", this.userID)
        .single();
      if (error) throw error;
      return this.processAllQuery(data);
    }

    // Guest single query
    const { data, error } = await sb.from("cards").select(Q.AllGuest.selector).eq("id", id).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Create a new card(s)
   * If multiple cards needs to be created, do n requests to the server.
   * This is because I am lazy.
   *
   * @param value - The card or an array of cards to create.
   * @returns A promise that resolves to the created card(s)
   */
  async create(value: I): Promise<R>;
  async create(value: I[]): Promise<R[]>;
  async create(value: I | I[]): Promise<R | R[]> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to create a card");
    if (Array.isArray(value)) return Promise.all(value.map((v) => this.create(v)));
    if (value.characters.length > 1) throw new Error("Only one character per card is supported for now.");

    const character = value.characters[0];
    const avatar = value.characters[0].avatar;
    const banner = value.characters[0].banner;
    const avatarFileName = await uploadFile(avatar);
    const bannerFileName = banner ? await uploadFile(banner) : undefined;
    console.log("calling create");
    const res = await client.card.create.query({
      ...value,
      character: {
        ...character,
        avatarFileName,
        bannerFileName
      }
    });
    if (!res) throw new Error("Failed to create card, nothing returned from server.");

    return res;
  }

  /**
   * Updates one or more cards by their ID.
   *
   * @param value - The value to apply for the update.
   * @returns A promise that resolves to the updated card(s).
   */
  async update(value: U): Promise<R>;
  async update(value: U[]): Promise<R[]>;
  async update(value: U | U[]): Promise<R | R[]> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to update a card");
    if (Array.isArray(value)) return Promise.all(value.map(async (v) => this.update(v)));

    const { id, title, tagline, isNSFW, isPrivate, characters } = value;

    const { avatar, banner } = characters[0];
    const avatarFileName = avatar ? await uploadFile(avatar) : undefined;
    const bannerFileName = banner ? await uploadFile(banner) : undefined;

    const character = {
      id,
      name: characters[0].name,
      description: characters[0].description,
      greeting: characters[0].greeting,
      definitions: characters[0].definitions,
      message_examples: characters[0].message_examples,
      avatarFileName,
      bannerFileName,
      isNSFW: characters[0].isNSFW,
      isPrivate: characters[0].isPrivate
    };

    const updatedCard = await client.card.update.mutate({
      id,
      title,
      tagline,
      isNSFW,
      isPrivate,
      character
    });

    if (!updatedCard) throw new Error("Failed to update card, nothing returned from server.");

    return updatedCard;
  }

  /**
   * Retrieves a Card by the associated chat ID(s).
   *
   * @param id - The ID or array of IDs of the chat(s) to retrieve the Card for.
   * @returns The Card resource associated with the provided chat ID.
   */
  async getByChatID(id: ID): Promise<R>;
  async getByChatID(id: ID[]): Promise<R[]>;
  async getByChatID(id: ID | ID[]): Promise<R | R[]> {
    if (!this.userID) throw new Error("User must be authenticated to get cards by chat ID");
    if (Array.isArray(id)) {
      const { data, error } = await sb
        .from("chats")
        .select(`cards!inner (${Q.All.selector})`)
        .in("id", id)
        .eq("cards.card_likes.profile_id", this.userID);
      if (error) throw error;
      const cards = data.map((d) => d.cards);
      return this.processAllQuery(cards);
    }

    const { data, error } = await sb
      .from("chats")
      .select(`cards!inner (${Q.All.selector})`)
      .eq("id", id)
      .eq("cards.card_likes.profile_id", this.userID)
      .single();
    if (error) throw error;
    return this.processAllQuery(data.cards);
  }

  /**
   * This is a major hotpath, so we need to use a trpc procedure + custom SQL instead of a PostgREST query.
   */
  async getPage(options?: DeepPartial<GetPageOptions>): Promise<R[]> {
    const opt = this.normalizeGetPageOptions(options);
    return await this.trpc.card.getPage.query(opt);
  }

  async getLastPageNumber(options?: DeepPartial<GetLastPageOptions>): Promise<number> {
    const opt = this.normalizeGetPageOptions(options) as GetLastPageOptions;
    const q = sb
      .from("cards")
      .select(...Q.Count.selector)
      .is("is_private", false);
    if (!opt.filter.nsfwOK) q.is("is_nsfw", false);

    const searchText = opt.filter.searchText;
    if (searchText) q.or(`title.ilike.%${searchText}%,tagline.ilike.%${searchText}%,name.ilike.%${searchText}%`);
    const { count, error } = await q;
    if (error) throw error;

    // Count is inexact so we subtract 1 to be safe
    return Math.ceil((count || 0) / opt.pageSize) - 1;
  }

  /**
   * Unlinks a character from a card.
   *
   * @param cardID - The card to unlink the character from.
   * @param characterID - The character or array of characters to unlink from the card.
   * @returns A Promise that resolves when the unlinking is complete.
   */
  async unlinkCharacter(cardID: ID, characterID: ID | ID[]): Promise<void> {
    if (!Array.isArray(characterID)) characterID = [characterID];
    const { error } = await sb.from("cards_characters").delete().eq("card_id", cardID).in("character_id", characterID);
    if (error) throw error;
  }

  /**
   * Retrieves a list of featured cards.
   * Featured cards are cards that are in the featured table.
   *
   * @returns An array of featured cards.
   */
  async getFeatured(): Promise<R[]> {
    let q;
    if (this.userID) {
      q = sb.from("featured").select(`cards(${Q.All.selector})`).eq("cards.card_likes.profile_id", this.userID);
    } else {
      q = sb.from("featured").select(`cards(${Q.AllGuest.selector})`);
    }
    const { data, error } = await q;
    if (error) throw error;
    const mapped = data.flatMap((d) => d.cards || []);
    return this.processAllQuery(mapped);
  }

  /**
   * Retrieves card data for a card form.
   * Because we need to enforce that *only* the creator of the card could view all card info,
   * This action musts be performed in a server side secured context.
   *
   * @param id - The ID of the card
   * @returns The card form's data
   */
  async getFormCard(id: number): Promise<FormCard> {
    return this.trpc.card.getFormCard.query(id);
  }

  /**
   * Apply the get page options to the supplied supabase query.
   * This modifies the query in place.
   *
   * @param q - The supabase query to apply the options to.
   * @param options - The options to apply.
   * @returns The mutated query with the applied options.
   */
  private applyGetPageOptions(q: Q.All.Builder | Q.Count.Builder | Q.AllGuest.Builder, options: GetPageOptions) {
    const opt = options;
    const rangeStart = opt.page * opt.pageSize;
    // -1 to make range non-inclusive
    const rangeEnd = rangeStart + opt.pageSize - 1;
    q.is("is_private", false).range(rangeStart, rangeEnd);
    if (!opt.filter.nsfwOK) {
      q.is("is_nsfw", false);
    }

    const searchText = opt.filter.searchText;
    if (searchText) q.or(`title.ilike.%${searchText}%,tagline.ilike.%${searchText}%,name.ilike.%${searchText}%`);
    const timeRange = this.timeFilterToTimeRange(opt.filter.period);
    if (timeRange.start) q.gte("created_at", timeRange.start);
    if (timeRange.end) q.lte("created_at", timeRange.end);

    const ascending = opt.order === "asc";
    switch (opt.ranking) {
      case "hot":
        q.order("hotness", { ascending });
        break;
      case "top":
        q.order("total_message_count", { ascending });
        break;
      case "new":
        q.order("created_at", { ascending });
        break;
    }
    return q;
  }

  private normalizeGetPageOptions(options?: DeepPartial<GetPageOptions>): GetPageOptions {
    return {
      page: options?.page ?? 0,
      pageSize: options?.pageSize ?? 30,
      ranking: options?.ranking ?? "hot",
      order: options?.order ?? "desc",
      filter: {
        period: options?.filter?.period ?? "all",
        nsfwOK: options?.filter?.nsfwOK ?? false,
        searchText: options?.filter?.searchText ?? ""
      }
    };
  }

  private timeFilterToTimeRange(timeFilter: Period): TimeRange {
    const now = new Date();
    switch (timeFilter) {
      case "day":
        return {
          start: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toISOString(),
          end: now.toISOString()
        };
      case "week":
        return {
          start: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).toISOString(),
          end: now.toISOString()
        };
      case "month":
        return {
          start: new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).toISOString(),
          end: now.toISOString()
        };
      case "year":
        return {
          start: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).toISOString(),
          end: now.toISOString()
        };
      case "all":
      default:
        return { start: undefined, end: undefined };
    }
  }

  async like(id: number): Promise<void> {
    if (!this.userID) throw new Error("User is not authenticated while attempting like like card");
    const likeValue: TablesInsert<"card_likes"> = {
      card_id: id,
      profile_id: this.userID
    };
    const { error } = await sb.from("card_likes").insert(likeValue);
    if (error) throw error;
  }

  async unlike(id: number): Promise<void> {
    if (!this.userID) throw new Error("User is not authenticated while attempting like unlike card");
    const { error } = await sb.from("card_likes").delete().eq("card_id", id).eq("profile_id", this.userID);
    if (error) throw error;
  }

  /**
   * Processes the raw response data from persistent storage and returns a Card (R)esource.
   *
   * @param value - The raw data from the database, either a single object or an array of objects.
   * @returns A Card (R)esource object.
   */
  private async processAllQuery(value: Q.All.Data[] | Q.AllGuest.Data[]): Promise<R[]>;
  private async processAllQuery(value: Q.All.Data | Q.AllGuest.Data): Promise<R>;
  private async processAllQuery(
    value: Q.All.Data | Q.All.Data[] | Q.AllGuest.Data | Q.AllGuest.Data[]
  ): Promise<R | R[]>;
  private async processAllQuery(
    value: Q.All.Data | Q.All.Data[] | Q.AllGuest.Data | Q.AllGuest.Data[]
  ): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map((r) => this.processAllQuery(r)));
    const characters = this.character.processAllQuery(value.characters);
    const createdBy = value.profiles?.username || "unknown";

    let isLiked;
    // If there's a card_likes field, we check if the user has liked the card
    if ("card_likes" in value) {
      isLiked = value.card_likes.some((l) => l.profile_id === this.userID);
    }
    // If there isn't a card_likes field, this a guest's query, they couldn't have liked it
    else {
      isLiked = false;
    }

    return {
      id: value.id,
      characters,
      title: value.title,
      tagline: value.tagline,
      createdBy,
      externalCreatedBy: value.external_created_by ?? undefined,
      createdAt: value.created_at,
      updatedAt: value.updated_at ?? undefined,
      chatCount: value.chat_count,
      messageCount: value.total_message_count,
      likeCount: value.total_like_count,
      isLiked,
      isNSFW: value.is_nsfw,
      isPrivate: value.is_private
    };
  }
}
